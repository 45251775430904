import React from "react";
import { NavLink } from "react-router-dom";
import "./style.css";
import { useGlobalStore } from "stores/global";

function NavigationItem({ path, text, icon }) {

  const language = useGlobalStore(state => state.user_info.language);
  return (
    <NavLink
      className={({ isActive }) =>
        isActive ? "bottom-nav__link active" : "bottom-nav__link"
      }
      to={path}
    >
      <img className="bottom-nav__img" src={icon} alt="" />
      <span className="bottom-nav__text" style={language !== "en" ? {fontWeight:600}:{}}>{text}</span>
    </NavLink>
  );
}

export default NavigationItem;
